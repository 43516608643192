import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Col,
    Container,
    Row,
} from "reactstrap";
import ReactLoader from '../../component/ReactLoader';
import Header from "../../common/header";
import { Link } from "react-router-dom";
import DollarIco from "../../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
const config = require('../../config.json')

export default function Level({ ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [inc, setInc] = useState({});
    const handelTeam = useCallback(async () => {
        setLoading(true)
        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "code": 'FRIN'
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}income`,
            headers: {
                'address': loginData?.address,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.status) {

                    setInc(response.data?.info)
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White FX',
                        text: response.data?.message
                    });
                }

                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])
    useEffect(() => {
        handelTeam()

    }, [handelTeam])
    return (
        <div>
            <Header />
            {loading ? <><ReactLoader /></> :
                <div className="mb-3 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
                    <div className="dashboard mb-5 mb-md-0 main-p">
                        <Container className="p-0">
                            <div className="mb-2 ">
                                <Row>
                                    <Col xs="3">
                                        <Link className="bg-site-primary btn btn-sm" block style={{ color: "white" }} to="/package">
                                            Package
                                        </Link>
                                    </Col>
                                    <Col xs="6">
                                        <Button className="bg-site-primary me-2" block style={{ color: "white" }}>
                                            Franchise Income
                                        </Button>
                                    </Col>
                                    <Col xs="3">
                                        <Link className="bg-site-primary btn btn-sm" block style={{ color: "white" }} to="/reward">
                                            Earning
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col md="6">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Total Income</p>
                                                    <h6
                                                        className="mb-0  text-site-primary"
                                                        style={{ fontSize: "17px" }}
                                                    >
                                                        ${inc?.total}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Today Income</p>
                                                    <h6
                                                        className="mb-0  text-site-primary"
                                                        style={{ fontSize: "17px" }}
                                                    >
                                                        ${inc?.yday}

                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {inc?.data?.map((dt, idx) => (
                                    <Col key={idx + 1} md="4" className="mt-2">
                                        <div className="card h-100">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                            Date:
                                                        </h2>
                                                    </div>
                                                    <h2 className="card__body-date mb-0">
                                                        {dt?.dt}
                                                    </h2>
                                                </div>
                                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                            Amount:
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-price mb-0">
                                                            ${dt?.netAmount}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                            Level:
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-price mb-0">
                                                            {dt?.level}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                            Refrence ID:
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-price mb-0">
                                                            {dt?.refrence}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Container>

                    </div>
                </div>
            }
        </div>
    );
}