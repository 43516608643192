import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
// import { Link } from "react-router-dom";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
export default function Wallet({ ipAddress, loginData }) {
  const [loading, setLoading] = useState(false)
  const [trans, setTran] = useState(false)
  const [widrl, setwidrl] = useState(false)
  const [businessData, setBusinessData] = useState({})
  const [showWl, setshowwl] = useState(true)
  const [tranamt, setTranamt] = useState(0)
  const [withamt, setWithamt] = useState(0)
  const [showSl, setshowSl] = useState(false)
  const [wts, setWts] = useState({
    'mt': true,
    'mw': false,
    'wm': false,
    'tm': false
  })
  const [tm, setTm] = useState(0)
  const [wm, setWm] = useState(0)
  const handelTeam = useCallback(async () => {
    setLoading(true)
    let data = JSON.stringify({
      "address": loginData.address,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}wallet`,
      headers: {
        'address': loginData?.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        if (response.data?.status) {

          setBusinessData(response.data?.info)
          setTran(response.data?.info?.is_self)
          setwidrl(response.data?.info?.is)

        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'White Capital Market',
            text: response.data?.message
          });
        }

        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])

  const handerWith = (e) => {
    e.preventDefault();
    let min = parseFloat(businessData?.minwr);
    setWithamt(parseFloat(withamt))
    if (withamt < min) {
      toast.warn("Please enter valid withdrawal amount");
      return;
    }
    let data = JSON.stringify({
      "address": loginData.address,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "amt": withamt
    });
    setLoading(true)
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}request`,
      headers: {
        'address': loginData?.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        setLoading(false)
        if (response.data?.status) {
          toast.info(response.data?.message);
          setWithamt(0);
          handelTeam();
        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'White Capital Market',
            text: response.data?.message
          });
        }

        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handerTran = (e) => {
    e.preventDefault();
    let min = parseFloat(businessData?.minslef);
    setTranamt(parseFloat(tranamt))
    if (tranamt < min) {
      toast.warn("Please enter valid Transfer amount");
      return;
    }
    let data = JSON.stringify({
      "address": loginData.address,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "amt": tranamt
    });
    setLoading(true)
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}transferMainTop`,
      headers: {
        'address': loginData?.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        setLoading(false)
        if (response.data?.status) {
          toast.info(response.data?.message);
          setTranamt(0);
          handelTeam();
        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'White Capital Market',
            text: response.data?.message
          });
        }

        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handerTrantm = (e) => {
    e.preventDefault();
    if (tm < 1 || tm > businessData?.wallet?.trading) {
      toast.warn("Please enter valid Transfer amount");
      return;
    }
    let data = JSON.stringify({
      "address": loginData.address,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "amt": tm
    });
    setLoading(true)
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}transferTradMain`,
      headers: {
        'address': loginData?.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        setLoading(false)
        if (response.data?.status) {
          toast.info(response.data?.message);
          setTm(0);
          handelTeam();
        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'White Capital Market',
            text: response.data?.message
          });
        }

        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handerTranwm = (e) => {
    e.preventDefault();
    if (wm < 1 || wm > businessData?.wallet?.working) {
      toast.warn("Please enter valid Transfer amount");
      return;
    }
    let data = JSON.stringify({
      "address": loginData.address,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "amt": wm
    });
    setLoading(true)
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}transferWorkMain`,
      headers: {
        'address': loginData?.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        setLoading(false)
        if (response.data?.status) {
          toast.info(response.data?.message);
          setWm(0);
          handelTeam();
        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'White Capital Market',
            text: response.data?.message
          });
        }

        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    handelTeam()
  }, [handelTeam])


  return (
    <div>
      <Header />
      {loading ? <><ReactLoader /></> :
        <div className="mb-3 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
          <div className="dashboard mb-5 mb-md-0 main-p">
            <Container className="p-0">
              <div className="mb-2">
                <Button className="bg-site-primary" block style={{ color: "white" }}>
                  Wallet
                </Button>
              </div>
              <Row>

                <Col md="6">
                  <div className="card mb-2">
                    <div className="card__body border-radius-all">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="mb-0 filter__card-label">Working Wallet Balance</p>
                          <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                            ${businessData?.wallet?.working}
                          </h6>
                        </div>
                        <div>
                          <p className="mb-0 filter__card-label">Toup Wallet Balance</p>
                          <h6
                            className="mb-0  text-site-primary"
                            style={{ fontSize: "14px" }}
                          >
                            ${businessData?.wallet?.topup}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="card mb-2">
                    <div className="card__body border-radius-all">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="mb-0 filter__card-label">Trading Wallet Balance</p>
                          <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                            ${businessData?.wallet?.trading}
                          </h6>
                        </div>
                        <div>
                          <p className="mb-0 filter__card-label">Compound Wallet Balance</p>
                          <h6
                            className="mb-0  text-site-primary"
                            style={{ fontSize: "14px" }}
                          >
                            ${businessData?.wallet?.compound}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="card mb-2">
                    <div className="card__body border-radius-all">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="mb-0 filter__card-label">Main Wallet Balance</p>
                          <h6
                            className="mb-0  text-site-primary"
                            style={{ fontSize: "17px" }}
                          >
                            ${businessData?.wallet?.main}

                          </h6>
                        </div>
                        <img src={DollarIco} alt="dollar" height="35px" />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="12" className="mt-2 mt-md-0">
                  <div className=" h-100 ">

                    <div className="d-flex align-items-center justify-content-between mt-1">
                      <Button
                        className="bg-primary text-white me-2 btn-sm"
                        style={{ fontSize: '10px' }}
                        type="submit"
                        onClick={(e) => { setWts({ ...wts, 'mt': false, 'mw': false, 'wm': true, 'tm': false }) }}
                      >
                        Working To Main Transfer
                      </Button>
                      <Button
                        className="bg-primary text-white "
                        style={{ fontSize: '10px' }}
                        type="submit"
                        onClick={(e) => { setWts({ ...wts, 'mt': false, 'mw': false, 'wm': false, 'tm': true }) }}
                      >
                        Trading To Main Transfer
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col md="12" className="mt-2 mt-md-0">
                  <div className=" h-100 ">

                    <div className="d-flex align-items-center justify-content-between mt-1">
                      <Button
                        className="bg-primary text-white me-2"
                        style={{ fontSize: '10px' }}
                        type="submit"
                        onClick={(e) => { setWts({ ...wts, 'mt': true, 'mw': false, 'wm': false, 'tm': false }) }}
                      >
                        Main to Topup Transfer
                      </Button>
                      <Button
                        className="bg-primary text-white "
                        style={{ fontSize: '10px' }}
                        type="submit"
                        onClick={(e) => { setWts({ ...wts, 'mt': false, 'mw': true, 'wm': false, 'tm': false }) }}
                      >
                        Withdrawal Request
                      </Button>

                    </div>
                  </div>
                </Col>
                {(wts.mt) &&
                  <>
                    {(trans) ?
                      <Col md="6" className="mt-2 mt-md-0" >
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Fund Transfer Request to TopUp Wallet
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Main Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.main}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <Input
                                placeholder="0"
                                className="m-2"
                                required
                                onChange={(e) => setTranamt(e.target.value)}
                                value={tranamt}
                              />
                            </div>
                            <div className="text-center mt-3">
                              <Button
                                className="bg-primary px-5 text-white"
                                onClick={handerTran}
                                type="submit"
                              >
                                Transfer Now
                              </Button>
                              <p className="mt-2 mb-2  card__body-note">
                                Note: Can't Withdraw TopUp Wallet Balance<br />
                                Minimum Transfer : ${businessData?.minslef}
                              </p>
                            </div>

                          </div>
                        </div>
                      </Col>
                      : <Col md="6" className="mt-2 mt-md-0" >
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Fund Transfer Request to TopUp Wallet
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Main Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.main}
                              </h2>
                            </div>
                            <div className="text-center mt-3">
                              <p className="mt-2 mb-2  card__body-note">
                                Note: Can't Withdraw TopUp Wallet Balance<br />
                                Minimum Transfer : ${businessData?.minslef}
                              </p>
                            </div>

                          </div>
                        </div>
                      </Col>
                    }
                  </>}
                {(wts.mw) &&
                  <>
                    {(widrl) ?
                      <Col md="6" className="mt-2 mt-md-0">
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Withdrawal Request to Address
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Main Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.main}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <Input
                                placeholder="0"
                                className="m-2"
                                required
                                onChange={(e) => setWithamt(e.target.value)}
                                value={withamt}
                              />
                            </div>
                            <div className="text-center mt-3">
                              <Button
                                className="bg-primary px-5 text-white"
                                onClick={handerWith}
                                type="submit"
                              >
                                Request Now
                              </Button>
                              <p className="mt-2 mb-2  card__body-note">
                                Note: Minimum Withdrawal Amount : $ {businessData?.minwr}
                              </p>
                            </div>

                          </div>
                        </div>
                      </Col>
                      :
                      <Col md="6" className="mt-2 mt-md-0">
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Withdrawal Request to Address
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Main Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.main}
                              </h2>
                            </div>
                            <div className="text-center mt-3">
                              <p className="mt-2 mb-2  card__body-note">
                                Note: Minimum Withdrawal Amount : $ {businessData?.minwr}
                              </p>
                            </div>

                          </div>
                        </div>
                      </Col>
                    }
                  </>
                }
                {(wts.tm && businessData?.isTcw) &&
                  <>
                    {(businessData?.wallet?.trading > 1) ?
                      <Col md="6" className="mt-2 mt-md-0" >
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Fund Transfer <br />
                            Trading Wallet to Main Wallet
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Trading Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.trading}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <Input
                                placeholder="0"
                                className="m-2"
                                required
                                onChange={(e) => setTm(e.target.value)}
                                value={tm}
                              />
                            </div>
                            <div className="text-center mt-3">
                              <Button
                                className="bg-primary px-5 text-white"
                                onClick={handerTrantm}
                                type="submit"
                              >
                                Transfer Now
                              </Button>
                            </div>

                          </div>
                        </div>
                      </Col>
                      : <Col md="6" className="mt-2 mt-md-0" >
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Fund Transfer <br />
                            Trading Wallet to Main Wallet
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Trading Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.trading}
                              </h2>
                            </div>
                            <div className="text-center mt-3">
                              <p className="mt-2 mb-2  card__body-note">
                                Note: Minimum amount $1<br />
                                {businessData?.isTcwMsg}
                              </p>
                            </div>

                          </div>
                        </div>
                      </Col>
                    }
                  </>}
                {(wts.wm) &&
                  <>
                    {(businessData?.wallet?.working > 1) ?
                      <Col md="6" className="mt-2 mt-md-0" >
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Fund Transfer <br />
                            Working Wallet to Main Wallet
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Working Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.working}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <Input
                                placeholder="0"
                                className="m-2"
                                required
                                onChange={(e) => setWm(e.target.value)}
                                value={wm}
                              />
                            </div>
                            <div className="text-center mt-3">
                              <Button
                                className="bg-primary px-5 text-white"
                                onClick={handerTranwm}
                                type="submit"
                              >
                                Transfer Now
                              </Button>
                            </div>

                          </div>
                        </div>
                      </Col>
                      : <Col md="6" className="mt-2 mt-md-0" >
                        <div className=" card h-100 mt-2">
                          <div className=" card__header border-radius-top">
                            Fund Transfer <br />
                            Working Wallet to Main Wallet
                          </div>
                          <div className=" card__body border-radius-bottom">

                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-2  card__body-label">
                                Working Wallet
                              </h2>
                              <h2 className="mb-2  card__body-label text-site-primary">
                                ${businessData?.wallet?.working}
                              </h2>
                            </div>
                            <div className="text-center mt-3">
                              <p className="mt-2 mb-2  card__body-note">
                                Note: Minimum amount $1<br />
                              </p>
                            </div>

                          </div>
                        </div>
                      </Col>
                    }
                  </>}
              </Row>

              <Row>
                <Col md="12" className="mt-2 mt-md-0">
                  <div className=" h-100 mt-2">

                    <div className="d-flex align-items-center justify-content-between mt-1">
                      <Button
                        className="bg-primary text-white me-2 btn-sm"
                        style={{ fontSize: '10px' }}
                        type="submit"
                        onClick={() => { setshowwl(true); setshowSl(false); }}
                      >
                        Withdrawal History
                      </Button>
                      <Button
                        className="bg-primary text-white "
                        style={{ fontSize: '10px' }}
                        type="submit"
                        onClick={() => { setshowwl(false); setshowSl(true); }}
                      >
                        Transfer history
                      </Button>

                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                {(showWl) ?
                  businessData?.payout?.map((pay, idx) => (
                    <Col md="4" className="mt-2" key={idx}>
                      <div className="small-card-ns h-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="mb-0 withdraw-card__body-label">
                            Date
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label">
                            {pay?.dt}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Transfer Amount
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            ${pay?.amt}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Tax
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            ${pay?.tax}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Net Amount
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            ${pay?.net}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Status
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label ">
                            {pay?.status}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Tx-hash:
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            <a
                              href={`https://bscscan.com/tx/${pay?.th}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Transaction
                            </a>
                          </h2>
                        </div>
                      </div>
                    </Col>
                  ))
                  : <> </>}
                {/* {(showth)?
                        businessData?.transfer?.map((tp, idx)=>(
                        <Col md="4" className="mt-2" key={2}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {tp.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${tp?.amt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                               To : {tp?.user_lid}
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {tp.user_name} ({tp.user_mobile})
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                        : <> </>}
                    {(showrh)?
                        businessData?.recive?.map((tp, idx)=>(
                        <Col md="4" className="mt-2" key={2}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {tp.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${tp?.amt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                               From : {tp?.user_lid}
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {tp.user_name} ({tp.user_mobile})
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                        : <> </>} */}
                {(showSl) ?
                  businessData?.self?.map((tp, idx) => (
                    <Col md="4" className="mt-2" key={idx}>
                      <div className="small-card-ns h-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="mb-0 withdraw-card__body-label">
                            Date
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label">
                            {tp.dt}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Self Transfer Amount
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            ${parseFloat(tp?.amt).toFixed(3)}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            From : {tp?.fwallet}
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            To : {tp?.twallet}
                          </h2>
                        </div>
                      </div>
                    </Col>
                  ))
                  : <> </>}
              </Row>
            </Container>

          </div>
        </div>
      }
    </div>
  );
}