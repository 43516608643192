import React, { useRef, useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import Logo from "../assets/img/wfxinc.png";
import HomeIco from "../assets/img/HOME.webp";
import walletIco from "../assets/img/wallet.webp";
import LaunchpadIco from "../assets/img/buy.webp";
import BuyNow from "../assets/img/wallet.webp";
import Earnig from '../assets/img/inc.webp';
import BSCIco from "../assets/img/bnb.webp";
import SupportIco from "../assets/img/support.webp";
import teamIco from "../assets/img/team.webp";
import {
  UncontrolledDropdown, Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import axios from 'axios';
const config = require('../config.json')
export default function Header() {
  //const location = useLocation();
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  const appData = (JSON.parse(localStorage.getItem('appData')));

  const handelLogout = () => {
    console.log(appData)
    localStorage.removeItem('loginData');
    window.location.reload();

  }

  const truncateAddress = (address) => {
    if (!address) return "No Account";
    const match = address.match(
      /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  }


  return (
    <div>
      <div className="header d-none d-md-block sticky">
        <div className="header__wrapper header__wrapper__nav">
          <Link to="/home">
            <img src={Logo} alt="logo" className="header__wrapper-logo" />
          </Link>
          <Link to="/home">
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"

            >
              <img src={HomeIco} alt="home" height="30px" className="me-2" />
              {/* <i
                    className={`fas fa-tv me-2 ${
                      ("index") && "text-site-primary"
                    }`}
                  ></i> */}
              Home
            </div>
          </Link>
          <Link to="/wallet">
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"

            >
              <img
                src={walletIco}
                alt="academy"
                height="30px"
                className="me-2"
              />
              Wallet
            </div>

          </Link>
          <Link to="/topup">
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer "
            >
              <img
                src={BuyNow}
                alt="wallet"
                height="30px"
                className="me-2"
              />
              TopUp
            </div>
          </Link>
          <Link to={"/buy"}>
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer "

            >
              <img
                src={LaunchpadIco}
                alt="statement"
                height="30px"
                className="me-2"
              />
              BuyBot
            </div>
          </Link>
          <Link to={"/support"}>
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"

            >
              <img
                src={SupportIco}
                alt="Support"
                height="30px"
                className="me-2"
              />
              Support
            </div>
          </Link>
          <div className="d-flex align-items-center">
            <img src={BSCIco} alt="bsc" height="40px" className="me-2" />
            <UncontrolledDropdown
              className="me-2"
              direction="down"
            >
              <DropdownToggle
                caret
                className="header-mbl-content-account"
                color="primary"
              > {truncateAddress(loginData?.address)} </DropdownToggle>
              <DropdownMenu style={{ font: '10px', lineHeight: "0px", minWidth: "20px" }} className="bg-primary text-center">
                <DropdownItem className="bg-primary text-white" onClick={handelLogout} style={{ fontSize: "10px" }}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

        </div>
      </div>
      <div className="d-block d-md-none">

        <div className="header-mbl sticky">
          <div className="header-mbl-bg">
            <div className="header-mbl-content">
              <img
                src={Logo}
                alt="logo"
                className="header-mbl-content-logo"
              />
              <div className="d-flex align-items-center">
                <img src={BSCIco} alt="bsc" height="30px" className="me-2" />
                <UncontrolledDropdown
                  className="me-2"
                  direction="down"
                >
                  <DropdownToggle
                    caret
                    className="header-mbl-content-account"
                    color="primary"
                  > {truncateAddress(loginData?.address)} </DropdownToggle>
                  <DropdownMenu style={{ font: '10px', lineHeight: "0px", minWidth: "20px" }} className="bg-primary text-center">
                    <DropdownItem className="bg-primary text-white" onClick={handelLogout} style={{ fontSize: "10px" }}>
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-nav">
          <Link to='/home'>
            <div className={`d-flex flex-column justify-content-center align-items-center pointer  "text-site-primary"}`}>
              <img src={HomeIco} alt="home" height="30px" className="mb-1" />
              <p className="mb--1">Home</p>
            </div>
          </Link>
          <Link to="/wallet">
            <div className={`d-flex flex-column justify-content-center align-items-center pointer "text-site-primary"}`}>
              <img src={walletIco} alt="wallet" height="30px" className="mb-1" />
              <p className="mb--1">Wallet</p>
            </div>
          </Link>

          <Link to={"/team"}>
            <div className={`d-flex flex-column justify-content-center align-items-center pointer "text-site-primary"}`}>
              <img src={teamIco} alt="launchapad" height="30px" className="mb-1" />
              <p className="mb--1">Team</p>
            </div>
          </Link>
          <Link to="/topup">
            <div className={`d-flex flex-column justify-content-center align-items-center pointer "text-site-primary"}`}>
              <img src={BuyNow} alt="download" height="30px" className="mb-1" />
              <p className="mb--1">TopUp</p>
            </div>
          </Link>
          <Link to={"/reward"}>
            <div className={`d-flex flex-column justify-content-center align-items-center pointer "text-site-primary"}`}>
              <img src={Earnig} alt="support" height="30px" className="mb-1" />
              <p className="mb--1">Earning</p>
            </div>
          </Link>
        </div>
      </div>
      {(appData?.scroll) ?
        <Carousel className="slider-page-header slider" controls indicators wrap fade style={{ zIndex: '-1' }}>

          {appData?.scroll.map((sc, i) => (
            //console.log(sc)
            <Carousel.Item interval={6000} key={i + 1}>
              <img src={sc.img} className="d-block w-100 slider-img" style={{ borderRadius: '12px' }} alt="Slider Images" />
            </Carousel.Item>
          ))}
        </Carousel>
        :
        <Carousel className="slider-page-header slider" controls="false" indicators="fasle" wrap fade style={{ zIndex: '-1' }}>
          <Carousel.Item interval={10000}>
            <img src='https://firebasestorage.googleapis.com/v0/b/wfxinc-4c72a.appspot.com/o/1.png?alt=media&token=fb13e243-c769-4314-b145-1b24e12e2746' className="d-block w-100 slider-img" style={{ borderRadius: '12px' }} alt="First slide" />

          </Carousel.Item>
        </Carousel>

      }

    </div>
  )
}