import React from "react";
import {
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import Header from "../common/header";
import './my.css';
export default function Soon() {


  return (
    <div>
      <Header />

      <>
        <div className="mb-3 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px", }} >
          <div className="dashboard mb-5 mb-md-0 main-p">
            <Container className="p-0">
              <Col md="12">
                <div className="card mt-2">
                  <div className="card__body border-radius-all">
                    <div className=" text-center">
                      <p className="mb-0 filter__card-label">Welcome  To White Capital Market</p>
                    </div>
                    <div className=" text-center">
                      <h5 >Comming Soon</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Container>

          </div>
        </div>
      </>

    </div>
  );
}