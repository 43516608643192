import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label, Spinner } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Logo from "../assets/img/wfxinc.png";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')

export default function Register({ ipAddress }) {
    const navigate = useNavigate();
    const [splid, setSplid] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [lid, setlid] = useState('')
    const [address, setAddress] = useState('')
    const [isreq, setIsreq] = useState(false)
    const [ischeck, setChk] = useState(false)
    const [pischeck, setChkp] = useState(false)
    const [ptnm, setPtnm] = useState('')


    const handleRegister = async (e) => {
        e.preventDefault()
        if (!ischeck) {
            Swal.fire({
                icon: 'warning',
                title: 'White Capital',
                text: 'Please Read the Terms and Conditions'
            })
            return;
        }
        let data = JSON.stringify({
            "splid": splid,
            "ip": ipAddress,
            "name": name,
            "mobile": mobile,
            "email": email,
            "address": address
        });

        let axiosConfig = {
            method: 'post',
            url: `${config.baseUrl}register`,
            headers: {
                'ip': ipAddress,
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response)
                let res = (response.data);
                console.log(res);
                if (res.status) {
                    Swal.fire({
                        icon: 'info',
                        title: 'White Capital',
                        text: res.message
                    })
                    setlid(res?.data)
                    setIsreq(res.status)
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White Capital',
                        text: res.message
                    })

                    if (res.code === 30) {
                        navigate('/');
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleSlid = (e) => {
        e.preventDefault()
        checkSplid(splid);
    }

    function checkSplid(slid) {
        if (slid.length > 4 && ipAddress.length > 6) {
            let data = JSON.stringify({
                "splid": slid,

            });

            let axiosConfig = {
                method: 'post',
                url: `${config.baseUrl}checksponsor`,
                headers: {
                    'ip': ipAddress,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //   console.log(axiosConfig)
            axios.request(axiosConfig)
                .then((response) => {
                    let res = (response.data);
                    if (res.status) {
                        setSplid(slid)
                        // Swal.fire({
                        //     icon: 'info',
                        //     title: 'White Capital',
                        //     text: res.message
                        // })
                        setPtnm(res?.data?.spname + '(' + res?.data?.splid + ')')
                        setChkp(true)
                        toast.success(res?.message)
                    }
                    else {
                        setSplid('');
                        setPtnm('');
                        setChkp(false)
                        Swal.fire({
                            icon: 'warning',
                            title: 'White Capital',
                            text: res.message
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            toast.warn("Please enter Partner ID")
        }
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const type = queryParameters.get("slid")
        if (type) {
            if (splid.length < 3)
                checkSplid(type);
        }
    })
    return (
        <div className="auth-form flex-column">
            <div className="auth-form-header text-center">
                <img src={Logo} alt="logo" height="100px" />
            </div>
            <div className="auth-form__wrapper">
                {isreq ? <>
                    <div className="text-center ">
                        <h6 className="auth-form__wrapper-title mt-2 text-center">Welcome to White Capital</h6>
                        <p>Dear : {name}</p>
                        <p>User ID : {lid?.ulid}</p>
                        <p>Password : {lid?.password}</p>
                        <p className="text-center mb-0 mt-2">
                            <small className="pointer" onClick={() => navigate("/")}>
                                Login Now
                            </small>
                        </p>
                    </div>

                </> :
                    <>
                        {/* <h6 className="auth-form__wrapper-title mt-2 text-center">Register</h6> */}
                        <div className="">

                            <Label className="auth-form__wrapper-label mt-1">Partner Id <a className='text-black ms-5 ' onClick={handleSlid}>Check </a></Label>
                            <Input
                                placeholder="Please enter Partner id And Click on check"
                                required
                                id="sponsorId"
                                value={splid} onChange={(e) => { setSplid(e.target.value); }}
                            />
                            <span className="text-center text-info text-xs ps-3" style={{ fontSize: "12px" }}>{ptnm}</span>
                            {(pischeck) ?
                                <>
                                    <div>
                                        <Label className="auth-form__wrapper-label">Smart Chain Address</Label>
                                        <Input
                                            placeholder="Please enter Smart Chain Address"
                                            required
                                            id="address"
                                            value={address} onChange={(e) => setAddress(e.target.value)}
                                        />
                                        <Label className="auth-form__wrapper-label mt-1">Name</Label>
                                        <Input
                                            placeholder="Please enter your name"
                                            required
                                            value={name} onChange={(e) => setName(e.target.value)}
                                        />
                                        <Label className="auth-form__wrapper-label mt-1">Email</Label>
                                        <Input
                                            placeholder="Please enter your email"
                                            required
                                            type="email"
                                            value={email} onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <Label className="auth-form__wrapper-label mt-1">
                                            Contact Number
                                        </Label>
                                        <PhoneInput
                                            buttonClass="button-class"
                                            inputClass="input-class"
                                            country={"in"}
                                            required
                                            onChange={(phone) => setMobile(phone)}
                                        />
                                        <div className="d-flex ms-4 mt-2">
                                            <Input
                                                placeholder="Chek Box"
                                                type='checkbox'
                                                required
                                                id="ischeck"
                                                value={(ischeck)} onChange={(e) => setChk(e.target.value)}
                                            />
                                            <Label className="auth-form__wrapper-label mt-2 me-4">I have read and agree to the <a href="https://wfxinc.pages.dev/terms" target="_blank">Terms of Use and Privacy Policy</a>. </Label>
                                        </div>
                                        <Button
                                            block
                                            className="mt-3 bg-site-primary text-white"
                                            type="submit"
                                            onClick={handleRegister}
                                        >
                                            {"Register"}
                                        </Button>
                                    </div>

                                    <p className="text-center mb-0 mt-2">
                                        <small className="pointer" onClick={() => navigate("/")}>
                                            Login
                                        </small>
                                    </p>
                                </>
                                :
                                <Button
                                    block
                                    className="mt-3 bg-site-primary text-white"
                                    type="submit"
                                    onClick={handleSlid}
                                >
                                    Check Now
                                </Button>
                            }
                        </div>
                    </>}

            </div>
        </div>
    );
};

