import React, { useEffect, useState, useContext } from "react";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Container,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import { Link } from "react-router-dom";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const config = require('../config.json')

export default function Profile({ ipAddress, loginData }) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState();
  const [oldp, setOld] = useState('');
  const [newp, setNew] = useState('');
  const [rnew, setRnew] = useState('');

  const handleProfile = (() => {

    let data = JSON.stringify({
      "address": loginData?.address,
      "ip": ipAddress,
      "ulid": loginData?.ulid
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}profile`,
      headers: {
        'address': loginData.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)

        if (response.data?.status) {

          setName(response.data.data?.uname)
          setEmail(response.data.data?.uemail)
          setMobile(response.data.data?.umobile)
        }
        else {
          toast.warn(response.data?.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  })

  useEffect(() => {
    handleProfile();
  }, [loginData])


  const handleProfileEdit = (e) => {
    e.preventDefault()
    let data = JSON.stringify({
      "address": loginData.address,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "uname": name,
      "uemail": email,
      "umobile": mobile,
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}profileedit`,
      headers: {
        'address': loginData.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    console.log(axiosConfig)
    axios.request(axiosConfig)
      .then((response) => {

        setName(response.data.data?.uname)
        setEmail(response.data.data?.uemail)
        setMobile(response.data.data?.umobile)

        toast.info(response.data?.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handlePass = (e) => {
    e.preventDefault()
    if (newp !== rnew && newp.length < 6) {
      Swal.fire({
        icon: 'warning',
        title: 'White Capital Market',
        text: 'Please Enter valid Password'
      });
    }
    let data = JSON.stringify({
      "address": loginData.address,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "opass": oldp,
      "npass": newp,
      "rpass": rnew
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}cpass`,
      headers: {
        'address': loginData.address,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response);
        if (response.status) {
          toast.info(response.data?.message);
          setOld('')
          setNew('')
          setRnew('')
          // Swal.fire({
          //   icon: 'info',
          //   title: 'White Capital Market',
          //   text: response.data.message
          // });
        }
        else {
          // Swal.fire({
          //   icon: 'warning',
          //   title: 'White Capital Market',
          //   text: response.message
          // });
          toast.warn(response.data?.message);
          setOld('')
          setNew('')
          setRnew('')
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      <Header />
      {loading ? <><ReactLoader /></> :
        <div className="mb-3 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
          <div className="dashboard mb-5 mb-md-0 main-p">
            <Container className="p-0">
              <div className="mb-2">
                <Button className="bg-site-primary" block style={{ color: "white" }}>
                  Profile
                </Button>
              </div>
              <div className="row">
                <div className='col-md-12'>
                  <div className="card mb-4 ">
                    <h5 className="card-header text-center text-white bg-primary">Your Profile</h5>
                    <div className="card-body">
                      <form id="formAccountSettings" onSubmit={handleProfileEdit} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                        <div className="row">
                          <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                            <label htmlFor="address" className="form-label">Binance Chain address</label>
                            <input type="text" id="address" name="address" className="form-control" placeholder="Address" value={loginData.address} readOnly />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                            <label htmlFor="name" className="form-label">Full Name</label>
                            <input type="text" id="name" name="companyName" className="form-control" placeholder="Your Name" value={name} onChange={(e) => { setName(e.target.value) }} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                            <label htmlFor="billingEmail" className="form-label">Email</label>
                            <input className="form-control" type="text" id="email" name="email" placeholder="Email ID" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          <div className="mb-3 col-sm-6">
                            <label htmlFor="taxId" className="form-label">Contact Number</label>
                            {/* <input className="form-control" type="text" id="mobile" name="mobile" placeholder="Contact Number"  value={mobile} onChange={(e) => {setMobile(e.target.value)}}/> */}
                            <PhoneInput
                              buttonClass="button-class"
                              inputClass="input-class"
                              country={"in"}
                              required
                              value={mobile}
                              onChange={(phone) => setMobile(phone)}
                            />
                          </div>

                        </div>
                        <div className="mt-2 text-center">
                          <button type="submit" onClick={handleProfileEdit} className="btn me-2 text-sm bg-primary text-white">Save changes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="card mb-4">
                    <h5 className="card-header text-center text-white bg-primary">Update Password</h5>
                    <div className="card-body">
                      <form id="formAccountSettings2" onSubmit={handlePass} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                        <div className="row">
                          <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                            <label htmlFor="opass" className="form-label">Old Password</label>
                            <input type="text" id="opass" name="companyName" className="form-control" placeholder="Old Passowd" value={oldp} onChange={(e) => { setOld(e.target.value) }} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                            <label htmlFor="billingnpl" className="form-label">New Password</label>
                            <input className="form-control" type="text" id="np" name="np" placeholder="Enter New Password" value={newp} onChange={(e) => { setNew(e.target.value) }} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                            <label htmlFor="billingenp" className="form-label">Re-enter New Password</label>
                            <input className="form-control" type="password" placeholder="Re-Enter New Password" value={rnew} onChange={(e) => { setRnew(e.target.value) }} />

                          </div>

                        </div>
                        <div className="mt-2 text-center">
                          <button type="submit" className="btn bg-primary me-2 text-sm text-white">Update Password</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </Container>

          </div>
        </div>
      }
    </div>
  );
}