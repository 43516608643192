import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Col,
    Container,
    Input,
    Row,
} from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
export default function Buy({ ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [packs, setPackages] = useState({})
    const [pack, setPack] = useState({})
    const [is, setis] = useState(false)
    const handelPack = useCallback(async () => {
        setLoading(true)
        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}packagebot`,
            headers: {
                'address': loginData?.address,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.status) {

                    setPackages(response.data?.info)
                    if (response.data?.info?.top > 99) {
                        setis(true)
                    }
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White Capital Market',
                        text: response.data?.message
                    });
                }

                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])
    useEffect(() => {
        handelPack()

    }, [handelPack])



    const handerBuy = (e, pk) => {
        e.preventDefault();
        let te = parseFloat(packs?.top);
        //console.log(te, amt, pack.minamt, packs?.top);
        if ((te < parseFloat(pack.minamt))) {
            toast.warn("Please enter valid Bot");
            return;
        }
        else {
            let data = JSON.stringify({
                "address": loginData.address,
                "ip": ipAddress,
                "ulid": loginData.ulid,
                "pack": pk.id
            });
            Swal.fire({
                title: "Do you want to Buy Bot?",
                showDenyButton: true,
                confirmButtonText: "Buy Now",
                denyButtonText: `Don't Buy`
            }).then((result) => {

                if (result.isConfirmed) {
                    setLoading(true)
                    let axiosConfig = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `${config.baseUrl}buybot`,
                        headers: {
                            'address': loginData?.address,
                            'ip': ipAddress,
                            'ulid': loginData.ulid,
                            'auth': loginData.auth,
                            'token': loginData.token,
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    axios.request(axiosConfig)
                        .then((response) => {
                            console.log(response.data)
                            setLoading(false)
                            if (response.data?.status) {
                                toast.info(response.data?.message);
                                handelPack()
                            }
                            else {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'White Capital Market',
                                    text: response.data?.message
                                });
                            }

                            setLoading(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (result.isDenied) {
                    toast.warn('You cancel Buy request')
                    setPack({})
                }
            });
        }
    }
    return (
        <div>
            <Header />
            {loading ? <><ReactLoader /></> :
                <div className="mb-4 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
                    <div className="dashboard mb-5 mb-md-0 main-p">
                        <Container className="p-0">
                            <div className="mb-2">
                                <Button className="bg-site-primary" block style={{ color: "white" }}>
                                    Buy Bot Package
                                </Button>
                            </div>
                            <Row>
                                <Col md="6">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">TopUp Wallet Balance</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "17px" }} >
                                                        $ {packs?.top}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Invested Amount</p>
                                                    <h6
                                                        className="mb-0  text-site-primary"
                                                        style={{ fontSize: "17px" }}
                                                    >
                                                        $ {packs?.toinv}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {(packs?.canbuy) &&
                                    (packs?.packs?.map((dt, idx) => (
                                        <Col md="4" className="mt-1 mt-md-0" key={idx + 1}>
                                            <div className=" card h-100 mt-2">
                                                <div className=" card__header border-radius-top bg-primary text-white">
                                                    {dt?.package_name}
                                                </div>
                                                <div className=" card__body border-radius-bottom">

                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h2 className="mb-2  card__body-label">
                                                            Bot Amount
                                                        </h2>
                                                        <h2 className="mb-2  card__body-label text-site-primary">
                                                            ${parseFloat(dt.minamt).toFixed(3)}
                                                        </h2>
                                                    </div>

                                                    <div className="text-center mt-3">
                                                        <Button className="bg-primary px-5 text-white"
                                                            type="submit"
                                                            onClick={(e) => handerBuy(e, dt)}
                                                        >
                                                            Buy Bot
                                                        </Button>
                                                    </div>



                                                </div>
                                            </div>
                                        </Col>
                                    )))
                                }
                            </Row>
                            <Row>
                                {packs?.invest?.map((dt, idx) => (
                                    <Col key={idx + 1} md="4" className="mt-2">
                                        <div className="card h-100 mt-2">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                            Package
                                                        </h2>
                                                    </div>
                                                    <h2 className="card__body-date mb-0">
                                                        {dt?.pname}
                                                    </h2>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                            Date:
                                                        </h2>
                                                    </div>
                                                    <h2 className="card__body-date mb-0">
                                                        {dt?.dt}
                                                    </h2>
                                                </div>
                                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                            Amount:
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <h2 className="card__body-price mb-0">
                                                            ${parseFloat(dt?.net_amount).toFixed(3)}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Container>

                    </div>
                </div>
            }
        </div>
    );
}