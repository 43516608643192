import React, { useEffect, useState, useCallback } from "react";
import {
    Badge,
    Button,
    Col,
    Collapse,
    Container,
    Input,
    Modal,
    ModalBody,
    Row,
    Spinner,
} from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import { Link } from "react-router-dom";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
export default function Statement({ ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [businessData, setBusinessData] = useState({})
    const handelTeam = useCallback(async () => {
        setLoading(true)
        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}earning`,
            headers: {
                'address': loginData?.address,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.status) {

                    setBusinessData(response.data?.info)
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White Capital Market',
                        text: response.data?.message
                    });
                }

                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])
    useEffect(() => {
        handelTeam()

    }, [handelTeam])
    return (
        <div>
            <Header />
            {loading ? <><ReactLoader /></> :
                <div className="mb-3 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
                    <div className="dashboard mb-5 mb-md-0 main-p">
                        <Container className="p-0">
                            <div className="mb-2">
                                <Button className="bg-site-primary" block style={{ color: "white" }}>
                                    Statement
                                </Button>
                            </div>
                            <Row>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Total Income</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.income?.total}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Today's Income</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.income?.today}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Yesturday Income</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.income?.yday}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <Link to={'/level'}>
                                        <div className="card mb-2">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Level Income</p>
                                                        <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                            ${businessData?.income?.level}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="4">
                                    <Link to={'/rewards'}>
                                        <div className="card mb-2">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Total Reward</p>
                                                        <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                            ${businessData?.income?.reward}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="4">
                                    <Link to={"/royalty"}>
                                        <div className="card mb-2">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Total Royalty</p>
                                                        <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                            ${businessData?.income?.royalty}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="4">
                                    <Link to={'/trading'}>
                                        <div className="card mb-2">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Trading Profit</p>
                                                        <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                            ${businessData?.income?.trading}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="4">
                                    <Link to={'/profit'}>
                                        <div className="card mb-2">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Profit Sharing</p>
                                                        <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                            ${businessData?.income?.profit}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="4">
                                    <Link to={'/freanch'}>
                                        <div className="card mb-2">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Total Franchise Income</p>
                                                        <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                            ${businessData?.income?.frech}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Working Wallet</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.wallet?.working}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Main Wallet</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.wallet?.main}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Topup Wallet</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.wallet?.topup}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Trading Wallet</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.wallet?.trading}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">CompoundTrading Wallet</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }} >
                                                        ${businessData?.wallet?.compound}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </div>
            }
        </div>
    );
}