import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import "./assets/scss/custom.scss";
import Home from './pages/home';
import Statement from './pages/statment';
import Reward from './pages/reward';
import Level from './pages/rewards/level';
import Freanch from './pages/rewards/freanch';
import Profit from './pages/rewards/profit';
import Support from './pages/support';
import Miner from './pages/miner';
import Direct from './pages/rewards/direct';
import Team from './pages/team';
import Business from './pages/buss';
import Profile from './pages/profile';
import Wallet from './pages/wallet';
import Buy from './pages/buy';
import Topup from './pages/topup';
import Login from './auth/login';
import Register from './auth/register';
import Forgot from './auth/forgot';
import PrivateRoute from './auth/PrivateRoute';
import Soon from './pages/soon';
import Comming from './pages/comming';
import Rewardinc from './pages/rewards/reward';
import Royalty from './pages/rewards/royalty';
import Trading from './pages/rewards/trading';
import Package from './pages/package';
function App() {
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  const [ipAddress] = useState('1:1:1:1');
  const [token, setToken] = useState('');
  const handleLogin = (token) => {
    setToken(token);
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home ipAddress={ipAddress} loginData={loginData} />}></Route>
            <Route path="/statment" element={<Statement ipAddress={ipAddress} loginData={loginData} />}></Route>
            <Route path='/reward' element={<Reward ipAddress={ipAddress} loginData={loginData} />} />

            <Route path='/support' element={<Support ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/package' element={<Package ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/team' element={<Team ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/buss' element={<Business ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/profile' element={<Profile ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/wallet' element={<Wallet ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/buy' element={<Buy ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/topup' element={<Topup ipAddress={ipAddress} loginData={loginData} />} />
            <Route path="/academy" element={<Soon />}></Route>
            <Route path='/level' element={<Level ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/rewards' element={<Rewardinc ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/royalty' element={<Royalty ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/trading' element={<Trading ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/freanch' element={<Freanch ipAddress={ipAddress} loginData={loginData} />} />
            <Route path='/profit' element={<Profit ipAddress={ipAddress} loginData={loginData} />} />
          </Route>
          <Route exact path="/" element={<Login ipAddress={ipAddress} onLogin={handleLogin} />}></Route>
          <Route path="/register" element={<Register ipAddress={ipAddress} />}></Route>
          <Route path="/forgot" element={<Forgot ipAddress={ipAddress} />}></Route>
          <Route path="*" element={<Comming />}></Route>
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
