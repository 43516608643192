import React from 'react'
import MM from '../assets/img/wfxinc.png'
import './ReactLoader.css'

export default function ReactLoader() {
  return (
    <div style={{ margin: '30%' }} className='text-center'>
      <img src={MM} alt="Page loading" width={100} height={100} className='pulse-animation' ></img>
    </div>
  )
}
