import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Col,
    Container,
    Input,
    Row,
    Label
} from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
// import { Link } from "react-router-dom";
import DollarIco from "../assets/img/icon.webp";
import TeamIcon from "../assets/img/profile.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
// import    { toast } from 'react-toastify';
const config = require('../config.json')
export default function Team({ ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [level, setLevel] = useState(0);

    const [datef, setdatef] = useState('');
    const [datet, setdatet] = useState('');
    const [team, setteam] = useState({});
    const handelSearch = (e) => {
        e.preventDefault()

        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "level": level,
            "fromdate": datef,
            "uptodate": datet,
            'search': true

        });
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}downline`,
            headers: {
                'address': loginData?.address,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.status) {

                    setteam(response.data?.info)
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White Capital Market',
                        text: response.data?.message
                    });
                }


            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handelTeam = useCallback(async () => {
        setLoading(true)
        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}downline`,
            headers: {
                'address': loginData?.address,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.status) {

                    setteam(response.data?.info)
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White Capital Market',
                        text: response.data?.message
                    });
                }

                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])
    useEffect(() => {
        handelTeam()

    }, [handelTeam])
    return (
        <div>
            <Header />
            {loading ? <><ReactLoader /></> :
                <div className="mb-3 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
                    <div className="dashboard mb-5 mb-md-0 main-p">
                        <Container className="p-0">
                            <div className="mb-2">
                                <Button className="bg-site-primary" block style={{ color: "white" }}>
                                    Team
                                </Button>
                            </div>
                            <Row>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Total Member's</p>
                                                    <h6
                                                        className="mb-0  text-site-primary"
                                                        style={{ fontSize: "17px" }}
                                                    >
                                                        {team?.total_downline}
                                                    </h6>
                                                </div>
                                                <img src={TeamIcon} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Active Member's</p>
                                                    <h6
                                                        className="mb-0  text-site-primary"
                                                        style={{ fontSize: "17px" }}
                                                    >
                                                        {team?.Active_downline}

                                                    </h6>
                                                </div>
                                                <img src={TeamIcon} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Total In-Active</p>
                                                    <h6
                                                        className="mb-0  text-site-primary"
                                                        style={{ fontSize: "14px" }}
                                                    >
                                                        {team?.Inactive_downline}
                                                    </h6>
                                                </div>
                                                <img src={TeamIcon} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Total Business</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }}>
                                                        ${team?.total_business?.total}
                                                    </h6>
                                                </div>
                                                <div>
                                                    <p className="mb-0 filter__card-label">Bot Business</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }}>
                                                        ${team?.total_business?.bot}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Trading Business</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }}>
                                                        ${team?.total_business?.trading}
                                                    </h6>
                                                </div>
                                                <div>
                                                    <p className="mb-0 filter__card-label">Compound Business</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "14px" }}>
                                                        ${team?.total_business?.compound}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="m-1">
                                <div className="card mt-2 col-12">
                                    <div className="card__header border-radius-top">
                                        <h2 className="team-heading text-site-secondary text-center mb-0">
                                            Downline Search
                                        </h2>
                                    </div>
                                    <div className="card__body border-radius-bottom">

                                        <Row className="align-items-center ">
                                            <Col md="2">
                                                <Label className="mb-2">Level</Label>
                                                <Input
                                                    placeholder="Select Level"
                                                    type="select"
                                                    name="level"
                                                    value={level}
                                                    onChange={(e) => setLevel(e.target.value)}
                                                >
                                                    <option value="0" disabled>
                                                        Select Level
                                                    </option>
                                                    {new Array(50).fill(1)?.map((opt, idx) => (
                                                        <option value={idx + 1} key={idx}>
                                                            Level {idx + 1}
                                                        </option>
                                                    ))}

                                                </Input>
                                            </Col>
                                            {/* <Col md="2" className="mt-1 mt-md-0">
                                    <Label className="mb-2">User ID</Label>
                                    <Input
                                        placeholder="User ID"
                                        value={ulid}
                                        onChange={(e) => setulid(e.target.value)}
                                    />
                                    </Col> */}
                                            <Col md="2" xs="6" className="mt-1 mt-md-0">
                                                <Label className="mb-2">From Date</Label>
                                                <Input
                                                    type="date"
                                                    value={datef}
                                                    onChange={(e) => setdatef(e.target.value)}
                                                />
                                            </Col>
                                            <Col md="2" xs="6" className="mt-1 mt-md-0">
                                                <Label className="mb-2">Upto Date</Label>
                                                <Input
                                                    type="date"
                                                    value={datet}
                                                    onChange={(e) => setdatet(e.target.value)}
                                                />
                                            </Col>
                                            <Col
                                                md="4"
                                                className="text-center d-md-flex justify-content-md-end mt-3 mt-md-4 mb-2 "
                                            >
                                                <Button className="bg-site-primary me-2 text-white" type="submit" onClick={handelSearch}>
                                                    Search Now
                                                </Button>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Row>
                            <Row>
                                {
                                    team?.downList?.map((t, idx) => (


                                        <Col md="4" className="mt-2" key={idx + 1}>
                                            <div className="card h-100">
                                                <div className="card__body border-radius-all">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className="mb-0 filter__card-label">
                                                            USER ID- {t.uslid}
                                                        </p>
                                                        <p className="mb-0 filter__card-label text-site-primary">
                                                            {t.status}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                                        <p className="mb-0 filter__card-label">
                                                            Sponsor: {t.splid}
                                                        </p>
                                                        <p className="mb-0 filter__card-label">
                                                            DOA:{t.adate}

                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                                        <p className="mb-0 filter__card-label">
                                                            Bot Invested:
                                                            ${(t.bot) ? parseFloat(t?.bot).toFixed(3) : 0}
                                                        </p>
                                                        <p className="mb-0 filter__card-label">
                                                            Level - {t.level}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                                        <p className="mb-0 filter__card-label">
                                                            Trading Invested:
                                                            ${(t.trading) ? parseFloat(t?.trading).toFixed(3) : 0}
                                                        </p>
                                                        <p className="mb-0 filter__card-label">
                                                            Compound Invested:
                                                            ${(t.compound) ? parseFloat(t?.compound).toFixed(3) : 0}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                            </Row>
                        </Container>

                    </div>
                </div>
            }
        </div>
    );
}