import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Col,
    Container,
    Input,
    Row,
} from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
export default function Miner({ ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [packs, setPackages] = useState({})
    const [amt, setamt] = useState(0)
    const handelPack = useCallback(async () => {
        setLoading(true)
        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}packagebuy`,
            headers: {
                'address': loginData?.address,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.status) {

                    setPackages(response.data?.info)
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White Capital Market',
                        text: response.data?.message
                    });
                }

                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])
    useEffect(() => {
        handelPack()

    }, [handelPack])

    const handerBuy = (e) => {
        e.preventDefault();
        if ((amt > packs.main) && (amt < packs.min)) {
            toast.warn("Please enter valid Amount");
            return;
        }
        else {
            let data = JSON.stringify({
                "address": loginData.address,
                "ip": ipAddress,
                "ulid": loginData.ulid,
                "amt": amt,
            });
            Swal.fire({
                title: "Do you want to Buy Package?",
                showDenyButton: true,
                confirmButtonText: "Re-Buy Now",
                denyButtonText: `Don't Re-Buy`
            }).then((result) => {

                if (result.isConfirmed) {
                    setLoading(true)
                    let axiosConfig = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `${config.baseUrl}rebuy`,
                        headers: {
                            'address': loginData?.address,
                            'ip': ipAddress,
                            'ulid': loginData.ulid,
                            'auth': loginData.auth,
                            'token': loginData.token,
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    axios.request(axiosConfig)
                        .then((response) => {
                            console.log(response.data)
                            setLoading(false)
                            if (response.data?.status) {
                                toast.info(response.data?.message);
                                setamt(0)
                                handelPack()
                            }
                            else {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'White Capital Market',
                                    text: response.data?.message
                                });
                            }

                            setLoading(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (result.isDenied) {
                    toast.warn('You cancel Re-Buy request')
                    setamt(0)
                }
            });
        }
    }
    return (
        <div>
            <Header />
            {loading ? <><ReactLoader /></> :
                <div className="mb-3 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
                    <div className="dashboard mb-5 mb-md-0 main-p">
                        <Container className="p-0">
                            <div className="mb-2">
                                <Button className="bg-site-primary" block style={{ color: "white" }}>
                                    Mining Statement
                                </Button>
                            </div>
                            <Row>
                                <Col md="6">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">Wallet</p>
                                                    <h6
                                                        className="mb-0  text-site-primary"
                                                        style={{ fontSize: "17px" }}
                                                    >
                                                        ${packs?.main}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6" className="mb-2 mt-md-0">
                                    <div className="card h-100">
                                        <div className="card__header border-radius-top">
                                            <h2 className="text-center card__body-heading mb-0">
                                                Available Miner Reward
                                            </h2>
                                        </div>
                                        <div className="card__body border-radius-bottom">
                                            <div className="d-flex mt-2 align-items-center justify-content-between">
                                                <h2 className="card__body-label">
                                                    Available Miner Reward
                                                </h2>
                                                <h2 className="card__body-label">
                                                    ${packs?.main}
                                                </h2>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                <Input
                                                    placeholder="0"
                                                    className="m-2"
                                                    required
                                                    onChange={(e) => setamt(e.target.value)}
                                                    value={amt}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-2">
                                                <Button className="bg-site-primary" style={{ color: "white" }} block onClick={handerBuy} >
                                                    {"Rebuy-Mining Now"}
                                                </Button>
                                            </div>
                                            <p className="mb-0 card__body-note text-center mt-2 mb-2">
                                                Note: Min. Rebuy ${packs?.min}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {packs?.invest?.map((dt, idx) => (
                                    (dt?.is_compound == 1) ?
                                        <Col key={idx + 1} md="6" className="mt-2">
                                            <div className="card">
                                                <div className="card__body border-radius-all">
                                                    <div className="d-flex mt-2 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label me-1 mb-0">
                                                                {dt.pname} Compound
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                {/* ({item?.stakePackage?.message}) */}
                                                            </h2>
                                                        </div>
                                                        <h2 className="card__body-date mb-0">
                                                            {dt.dt}
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Package:
                                                            </h2>

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                ${parseFloat(dt?.invest_amount).toFixed(3)}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Compound Trading Income
                                                            </h2>

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-price mb-0">
                                                                {dt?.roim}% Monthly
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Compound for {dt?.compound_year} Yeas
                                                            </h2>

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-price mb-0">
                                                                Clear On : {dt?.edt}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        :
                                        <Col key={idx + 1} md="6" className="mt-2">
                                            <div className="card">
                                                <div className="card__body border-radius-all">
                                                    <div className="d-flex mt-2 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label me-1 mb-0">
                                                                {dt.pname} {(dt?.is_compound == 1) ? <>Compound</> : ''}
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                {/* ({item?.stakePackage?.message}) */}
                                                            </h2>
                                                        </div>
                                                        <h2 className="card__body-date mb-0">
                                                            {dt.dt}
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Package:
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                ${parseFloat(dt?.invest_amount).toFixed(3)}
                                                            </h2>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                {(dt?.is_compound == 1) ? <>Compound</> : ''} Trading Income
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                {dt?.roim}% Monthly
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                ))}
                            </Row>
                        </Container>

                    </div>
                </div>
            }
        </div>
    );
}