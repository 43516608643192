import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Col,
    Container,
    Input,
    Row,
} from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
export default function Package({ ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [packs, setPackages] = useState({})
    const [selectpackt, setPackt] = useState({})
    const [is, setis] = useState(false)
    const [cis, setcis] = useState(false)
    const [amt, setamt] = useState(0)
    const [year, setyear] = useState(0)
    const [trad, setTrad] = useState(0);
    const [comp, SetComp] = useState(0);
    const handelPack = useCallback(async () => {
        setLoading(true)
        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}packagebuy`,
            headers: {
                'address': loginData?.address,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.status) {

                    setPackages(response.data?.info)
                    SetComp(response.data?.info?.wallet?.compound + response.data?.info?.total?.compound)
                    setTrad(response.data?.info?.wallet?.trading + response.data?.info?.total?.trading)
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'White Capital Market',
                        text: response.data?.message
                    });
                }

                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])
    useEffect(() => {
        handelPack()

    }, [handelPack])

    function selectPack(e, pk) {
        e.preventDefault();

        if (parseFloat(packs?.top) < parseFloat(pk.minamt)) {
            toast.warn("Select a valid Package");
            return;
        }
        console.log(pk);
        setPackt(pk);
        setis(true);
        setcis(false);
    }

    function selectcPack(e, pk) {
        e.preventDefault();

        if (parseFloat(packs?.top) < parseFloat(pk.minamt)) {
            toast.warn("Select a valid Package");
            return;
        }
        console.log(pk);
        setPackt(pk);
        setcis(true);
        setis(false);
    }

    const handerBuy = (e) => {
        e.preventDefault();

        let te = (parseFloat(amt));
        if ((te < parseFloat(selectpackt.minamt)) || te > parseFloat(selectpackt.maxamt)) {
            toast.warn("Please enter valid amount to Buy " + selectpackt.package_name);
            return;
        }
        else {
            let data = JSON.stringify({
                "address": loginData.address,
                "ip": ipAddress,
                "ulid": loginData.ulid,
                "amt": amt,
                "pack": selectpackt.id
            });
            Swal.fire({
                title: "Do you want to Buy Package?",
                showDenyButton: true,
                confirmButtonText: "Buy Now",
                denyButtonText: `Don't Buy`
            }).then((result) => {

                if (result.isConfirmed) {
                    setLoading(true)
                    let axiosConfig = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `${config.baseUrl}buy`,
                        headers: {
                            'address': loginData?.address,
                            'ip': ipAddress,
                            'ulid': loginData.ulid,
                            'auth': loginData.auth,
                            'token': loginData.token,
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    axios.request(axiosConfig)
                        .then((response) => {
                            console.log(response.data)
                            setLoading(false)
                            if (response.data?.status) {
                                toast.info(response.data?.message);
                                handelPack()
                                setamt(0)
                                setis(false)
                                setPackt({})
                                handelPack()
                            }
                            else {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'White Capital Market',
                                    text: response.data?.message
                                });
                            }

                            setLoading(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (result.isDenied) {
                    toast.warn('You cancel Buy request')
                    setamt(0)
                    setis(false)
                    setPackt({})
                }
            });
        }
    }
    const handerBuyc = (e) => {
        e.preventDefault();
        let te = (parseFloat(amt));
        if ((te < parseFloat(selectpackt.minamt)) || te > parseFloat(selectpackt.maxamt)) {
            toast.warn("Please enter valid amount to Buy " + selectpackt.package_name);
            return;
        }
        else
            if (year < 1) {
                toast.warn("Please Select year for Compound");
                return;
            }
            else {
                let data = JSON.stringify({
                    "address": loginData.address,
                    "ip": ipAddress,
                    "ulid": loginData.ulid,
                    "amt": amt,
                    "pack": selectpackt.id,
                    "year": year
                });
                Swal.fire({
                    title: "Do you want to Buy Package?",
                    showDenyButton: true,
                    confirmButtonText: "Buy Now",
                    denyButtonText: `Don't Buy`
                }).then((result) => {

                    if (result.isConfirmed) {
                        setLoading(true)
                        let axiosConfig = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `${config.baseUrl}buycomp`,
                            headers: {
                                'address': loginData?.address,
                                'ip': ipAddress,
                                'ulid': loginData.ulid,
                                'auth': loginData.auth,
                                'token': loginData.token,
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };
                        axios.request(axiosConfig)
                            .then((response) => {
                                console.log(response.data)
                                setLoading(false)
                                if (response.data?.status) {
                                    toast.info(response.data?.message);
                                    handelPack()
                                    setamt(0)
                                    setcis(false)
                                    setyear(false)
                                    setPackt({})
                                }
                                else {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'White Capital Market',
                                        text: response.data?.message
                                    });
                                }

                                setLoading(false)
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else if (result.isDenied) {
                        toast.warn('You cancel Buy request')
                        setamt(0)
                        setcis(false)
                        setyear(false)
                        setPackt({})
                    }
                });
            }
    }
    return (
        <div>
            <Header />
            {loading ? <><ReactLoader /></> :
                <div className="mb-4 mb-md-0" style={{ overflowY: "auto", marginBottom: "50px" }} >
                    <div className="dashboard mb-5 mb-md-0 main-p">
                        <Container className="p-0">
                            <div className="mb-2">
                                <Button className="bg-site-primary" block style={{ color: "white" }}>
                                    Invest In  Package
                                </Button>
                            </div>
                            <Row>
                                <Col md="6">
                                    <div className="card mb-2">
                                        <div className="card__body border-radius-all">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 filter__card-label">TopUp Wallet Balance</p>
                                                    <h6 className="mb-0  text-site-primary" style={{ fontSize: "17px" }} >
                                                        $ {packs?.top}
                                                    </h6>
                                                </div>
                                                <img src={DollarIco} alt="dollar" height="35px" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {(trad > 0) ?
                                    <Col md="6">
                                        <div className="card mb-1">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Trading Amount (Trading Wallet)</p>
                                                        <h6
                                                            className="mb-0  text-site-primary"
                                                            style={{ fontSize: "17px" }}
                                                        >
                                                            $ {packs?.total?.trading} + ${packs?.wallet?.trading}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2 mt-0 text-center">
                                            <Button className="px-5 text-white custom-btn">
                                                Withdrawal All Trading Amount : ${trad}
                                            </Button>
                                        </div>
                                    </Col>
                                    : ''}
                                {(comp > 0) ?
                                    <Col md="6">
                                        <div className="card mb-1">
                                            <div className="card__body border-radius-all">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-0 filter__card-label">Compound Amount (Compound Wallet)</p>
                                                        <h6
                                                            className="mb-0  text-site-primary"
                                                            style={{ fontSize: "17px" }}
                                                        >
                                                            $ {packs?.total?.compound} + ${packs?.wallet?.compound}
                                                        </h6>
                                                    </div>
                                                    <img src={DollarIco} alt="dollar" height="35px" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="mb-2 mt-0 text-center">
                                            <Button className="px-5 text-white custom-btn">
                                                Withdrawal All Compound Amount : ${comp}
                                            </Button>
                                        </div>
                                    </Col>
                                    : ''}

                            </Row>
                            <div className="mb-2 mt-2">
                                <Button className="bg-site-primary" block style={{ color: "white" }}>
                                    Invest Options
                                </Button>
                            </div>
                            {(!is && !cis) ?
                                <Row>
                                    {packs?.packs?.map((dt, idx) => (
                                        <Col md="4" className="mt-1 mb-1 mt-md-0" key={idx + 1}>
                                            <div className=" card h-100 mt-2">
                                                <div className=" card__header border-radius-top bg-primary text-white">
                                                    {dt?.package_name} Invetment Package
                                                </div>
                                                <div className=" card__body border-radius-bottom">

                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h2 className="mb-2  card__body-label">
                                                            Package Amount
                                                        </h2>
                                                        <h2 className="mb-2  card__body-label text-site-primary">
                                                            ${parseFloat(dt.minamt).toFixed(3)}- ${parseFloat(dt.maxamt).toFixed(3)}
                                                        </h2>
                                                    </div>
                                                    <div className="text-center mt-3 d-flex justify-content-between">
                                                        <Button className="bg-primary px-5 me-2 text-white header-mbl-content-account"
                                                            type="submit"
                                                            onClick={(e) => selectPack(e, dt)}
                                                        >
                                                            Invest Trading
                                                        </Button>
                                                        <Button className="bg-primary px-5 text-white header-mbl-content-account"
                                                            type="submit"
                                                            onClick={(e) => selectcPack(e, dt)}
                                                        >
                                                            Invest Compound
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                                :
                                <>
                                    {(is) &&
                                        <Row>
                                            <Col md="12" className="mt-2 mt-md-0" >
                                                <div className=" card h-100 mt-2">
                                                    <div className=" card__header border-radius-top">
                                                        Buy Package
                                                    </div>
                                                    <div className=" card__body border-radius-bottom">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h2 className="mb-2  card__body-label">
                                                                {selectpackt.package_name}
                                                            </h2>
                                                            <h2 className="mb-2  card__body-label text-site-primary">
                                                                ${parseFloat(selectpackt.minamt).toFixed(3)}- ${parseFloat(selectpackt.maxamt).toFixed(3)}
                                                            </h2>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h2 className="mb-2  card__body-label">
                                                                TopUp Wallet
                                                            </h2>
                                                            <h2 className="mb-2  card__body-label text-site-primary">
                                                                ${packs?.top}
                                                            </h2>
                                                        </div>
                                                        <div className=" align-items-center justify-content-between mt-1">
                                                            <label className="ms-2">Enter amount</label>
                                                            <Input
                                                                placeholder="0"
                                                                className="m-2"
                                                                required
                                                                onChange={(e) => setamt(e.target.value)}
                                                                value={amt}
                                                            />
                                                        </div>
                                                        <div className="text-center mt-3">
                                                            <Button
                                                                className="bg-primary px-5 text-white"
                                                                onClick={handerBuy}
                                                                type="submit"
                                                            >
                                                                Buy Now
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {(cis) &&
                                        <Row>
                                            <Col md="12" className="mt-2 mt-md-0" >
                                                <div className=" card h-100 mt-2">
                                                    <div className=" card__header border-radius-top">
                                                        Invest in compound package
                                                    </div>
                                                    <div className=" card__body border-radius-bottom">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h2 className="mb-2  card__body-label">
                                                                {selectpackt.package_name}
                                                            </h2>
                                                            <h2 className="mb-2  card__body-label text-site-primary">
                                                                ${parseFloat(selectpackt.minamt).toFixed(3)}- ${parseFloat(selectpackt.maxamt).toFixed(3)}
                                                            </h2>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h2 className="mb-2  card__body-label">
                                                                TopUp Wallet
                                                            </h2>
                                                            <h2 className="mb-2  card__body-label text-site-primary">
                                                                ${packs?.top}
                                                            </h2>
                                                        </div>
                                                        <div className=" align-items-center d-flex justify-content-between mt-1">
                                                            <select className="form-control me-2" onChange={(e) => setyear(e.target.value)}>
                                                                <option>Select Year</option>
                                                                {packs?.cpy.map((key, i) => {
                                                                    return (
                                                                        <option key={i} value={key}>{key} Year</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <Input
                                                                className=""
                                                                required
                                                                onChange={(e) => setamt(e.target.value)}
                                                                placeholder="Enter amount"
                                                            />
                                                        </div>

                                                        <div className="text-center mt-3">
                                                            <Button
                                                                className="bg-primary px-5 text-white"
                                                                onClick={handerBuyc}
                                                                type="submit"
                                                            >
                                                                Buy Now
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </>
                            }

                            <Row className="mt-3">
                                {packs?.invest?.map((dt, idx) => (
                                    (dt?.is_compound == 1) ?
                                        <Col key={idx + 1} md="6" className="mt-2">
                                            <div className="card">
                                                <div className="card__body border-radius-all">
                                                    <div className="d-flex mt-2 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label me-1 mb-0">
                                                                {dt.pname} Compound
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                {/* ({item?.stakePackage?.message}) */}
                                                            </h2>
                                                        </div>
                                                        <h2 className="card__body-date mb-0">
                                                            {dt.dt}
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Package:
                                                            </h2>

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                ${parseFloat(dt?.invest_amount).toFixed(3)}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Compound Trading Income
                                                            </h2>

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-price mb-0">
                                                                {dt?.roim}% Monthly
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Compound for {dt?.compound_year} Yeas
                                                            </h2>

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-price mb-0">
                                                                Clear On : {dt?.edt}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        :
                                        <Col key={idx + 1} md="6" className="mt-2">
                                            <div className="card">
                                                <div className="card__body border-radius-all">
                                                    <div className="d-flex mt-2 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label me-1 mb-0">
                                                                {dt.pname} {(dt?.is_compound == 1) ? <>Compound</> : ''}
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                {/* ({item?.stakePackage?.message}) */}
                                                            </h2>
                                                        </div>
                                                        <h2 className="card__body-date mb-0">
                                                            {dt.dt}
                                                        </h2>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                Package:
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                ${parseFloat(dt?.invest_amount).toFixed(3)}
                                                            </h2>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <h2 className="card__body-label text-site-gray me-1 mb-0">
                                                                {(dt?.is_compound == 1) ? <>Compound</> : ''} Trading Income
                                                            </h2>
                                                            <h2 className="card__body-price mb-0">
                                                                {dt?.roim}% Monthly
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                ))}
                            </Row>

                        </Container>

                    </div>
                </div>
            }
        </div>
    );
}